import React from 'react';
import {PricingWrapper,PricingHeadingWrapper,PricingSubHeading,
    PricingHeading,PricingPlanCol,
    PricingPlanLine,ButtonWrapper,PricingPlanBtn,PricingPlanLightText,PricingPlanDarkText,PricingPlanColCenter,
    PricingPlanBtnCenter
} from './pricing.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';

const Pricing = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Pricing {
                    DelayConstant
                    PricingHeading
                    PricingSubHeading
                    PricingPlanCol{
                        PricingPlanHeading
                        PricingPlanImg
                        PricingPlanPrice
                        PricingPlanPriceSpan
                        PricingPlanDarkText{
                            Text
                        }
                        PricingPlanLightText{
                            Text
                        }
                        PricingPlanBtn
                    }
                }
            }
        }
    `);
    return (
        <PricingWrapper id="pricingContainer">
            <Container>
                <Row>
                    <Col md="12">
                        <PricingHeadingWrapper>
                            <Fade bottom delay={1*JSONData.app2Json.Pricing.DelayConstant}>
                                <PricingHeading>
                                    {JSONData.app2Json.Pricing.PricingHeading}
                                </PricingHeading>
                            </Fade>
                            <Fade bottom delay={2*JSONData.app2Json.Pricing.DelayConstant}>
                                <PricingSubHeading>
                                    {JSONData.app2Json.Pricing.PricingSubHeading}
                                </PricingSubHeading>
                            </Fade>
                        </PricingHeadingWrapper>
                    </Col>
                </Row>

                <Row>
                    <Col md="4">
                        <Fade bottom delay={(0+1)*JSONData.app2Json.Pricing.DelayConstant}>
                            <PricingPlanCol>
                                <h2>{JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanHeading}</h2>
                                <img src={JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanImg} alt=""/>
                                <i>{JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanPrice} / <span>{JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanPriceSpan}</span></i>
                                <PricingPlanLine/>
                                {
                                    JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                }
                                {
                                    JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanLightText.map((itemLight,idxLight) => {
                                        return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                    })
                                }
                                <ButtonWrapper>
                                    <PricingPlanBtn href="#">{JSONData.app2Json.Pricing.PricingPlanCol[0].PricingPlanBtn}</PricingPlanBtn>
                                </ButtonWrapper>
                            </PricingPlanCol>
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom delay={(1+1)*JSONData.app2Json.Pricing.DelayConstant}>
                            <PricingPlanColCenter>
                                <h2>{JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanHeading}</h2>
                                <img src={JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanImg} alt=""/>
                                <i>{JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanPrice} / <span>{JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanPriceSpan}</span></i>
                                <PricingPlanLine/>
                                {
                                    JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                }
                                {
                                    JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanLightText.map((itemLight,idxLight) => {
                                        return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                    })
                                }
                                <ButtonWrapper>
                                    <PricingPlanBtnCenter href="#">{JSONData.app2Json.Pricing.PricingPlanCol[1].PricingPlanBtn}</PricingPlanBtnCenter>
                                </ButtonWrapper>
                            </PricingPlanColCenter>
                        </Fade>
                    </Col>
                    <Col md="4">
                        <Fade bottom delay={(2+1)*JSONData.app2Json.Pricing.DelayConstant}>
                            <PricingPlanCol>
                                <h2>{JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanHeading}</h2>
                                <img src={JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanImg} alt=""/>
                                <i>{JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanPrice} / <span>{JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanPriceSpan}</span></i>
                                <PricingPlanLine/>
                                {
                                    JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanDarkText.map((itemDark,idxDark) => {
                                        return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                    })
                                }
                                {
                                    JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanLightText.map((itemLight,idxLight) => {
                                        return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                    })
                                }
                                <ButtonWrapper>
                                    <PricingPlanBtn href="#">{JSONData.app2Json.Pricing.PricingPlanCol[2].PricingPlanBtn}</PricingPlanBtn>
                                </ButtonWrapper>
                            </PricingPlanCol>
                        </Fade>
                    </Col>
                </Row>
            </Container>
        </PricingWrapper>
    );
}

export default Pricing;
