import styled from 'styled-components';

export const Commonbtn = styled.a`
    display: inline-block !important;
    background: none;
    color: #ffffff;
    position: relative;
    outline: none;
    border: none;
    text-decoration:none;
    padding: 8px 30px;
    border-radius: 50px;
    z-index: 2;
    font-size: 14px;
    font-weight:bold;
    -webkit-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    text-align: center;

    ::after{
        -webkit-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #0071e3;
        z-index: -1;
    }
    ::before{
        -webkit-transition: all 0.5s ease-in-out;
        -ms-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
        content: '';
        position: absolute;
        left: -350px;
        top: 0;
        height: 100%;
        width: 350px;
        background: #363636;
        z-index: -1;
    }
    :hover{
        color: #ffff00 !important;
    }
    :hover::before {
        left: 0;
        width: 100%;
    }
    :hover::after {
        left: 100%;
    }
`;

export const SectionHeading = styled.h2`
    margin-bottom:15px;
    text-align:center;
    line-height:1;
`;

export const SectionSubHeading = styled.h6`
    text-align:center;
    font-weight:500;
    line-height:1;
    margin-bottom:0px;
`;
