import React from 'react';
import {AppFeaturesSectionWrapper,AppFeaturesHeadingWrapper,
    AppFeaturesHeading,AppFeaturesSubHeading,AppFeaturesInner,AppFeaturesWrapper,AppFeaturesText,
    AppFeaturesLeftIcon,AppFeaturesCenter,CenterCol
} from './appfeatures.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const AppFeatures = () => {
    const JSONData  = useStaticQuery(graphql`{
  app2Json {
    AppFeatures {
      AppFeaturesHeading
      AppFeaturesSubHeading
      AppFeaturesInner1 {
        AppFeaturesLeftBold
        text
        AppFeaturesImg
      }
      AppFeaturesInner2 {
        AppFeaturesMobile {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
      }
      AppFeaturesInner3 {
        AppFeaturesLeftBold
        text
        AppFeaturesImg
      }
    }
  }
}
`);
        return (
            <AppFeaturesSectionWrapper>
                <Container>
                    <Row>
                        <Col md="12">
                            <Fade bottom delay={1*JSONData.app2Json.AppFeatures.DelayConstant}>
                                <AppFeaturesHeadingWrapper>
                                    <AppFeaturesHeading>{JSONData.app2Json.AppFeatures.AppFeaturesHeading}</AppFeaturesHeading>
                                    <AppFeaturesSubHeading>{JSONData.app2Json.AppFeatures.AppFeaturesSubHeading}</AppFeaturesSubHeading>
                                </AppFeaturesHeadingWrapper>
                            </Fade>
                        </Col>
                    </Row>

                    <AppFeaturesInner>
                        <Col lg="4" md="6">
                        <Fade left delay={(2)*JSONData.app2Json.AppFeatures.DelayConstant}>
                        {
                            JSONData.app2Json.AppFeatures.AppFeaturesInner1.map((item,idx) => {
                            return <AppFeaturesWrapper bottomMargin = {idx === 2 ? "0px":"30px"}>
                                <AppFeaturesLeftIcon>
                                        <img src={item.AppFeaturesImg} alt=""/>
                                </AppFeaturesLeftIcon>
                                <AppFeaturesText  paddingLeft={"0px"}>
                                    <h6>{item.AppFeaturesLeftBold}</h6>
                                    <p>{item.text}</p>
                                </AppFeaturesText>
                            </AppFeaturesWrapper>
                            })
                        }
                        </Fade>
                        </Col>

                        <CenterCol lg="4">
                            <AppFeaturesCenter>
                                <GatsbyImage
                                    image={JSONData.app2Json.AppFeatures.AppFeaturesInner2.AppFeaturesMobile.childImageSharp.gatsbyImageData}
                                    className="AppFeaturesMobile"
                                    imgStyle={{
                                        marginBottom:"0px",
                                        width:"100%"
                                    }}
                                    alt="" />
                            </AppFeaturesCenter>
                        </CenterCol>

                        <Col lg="4" md="6">
                        <Fade right delay={(2)*JSONData.app2Json.AppFeatures.DelayConstant}>
                        {
                            JSONData.app2Json.AppFeatures.AppFeaturesInner3.map((item,idx) => {
                            return <AppFeaturesWrapper bottomMargin = {idx === 2 ? "0px":"30px"}>
                                <AppFeaturesLeftIcon>
                                        <img src={item.AppFeaturesImg} alt=""/>
                                </AppFeaturesLeftIcon>
                                <AppFeaturesText paddingLeft={"10px"}>
                                        <h6>{item.AppFeaturesLeftBold}</h6>
                                        <p>{item.text}</p>
                                </AppFeaturesText>
                                </AppFeaturesWrapper>
                            })
                        }
                        </Fade>
                        </Col>
                    </AppFeaturesInner>
                </Container>
            </AppFeaturesSectionWrapper>
        );
}

export default AppFeatures;
