import React from 'react';
import {BannerWrapper,BannerContents,BannerContentLeft,BannerContentRight,
    BannerBtn,BannerImage} from './banner.style'
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            app2Json {
                Banner {
                    DelayConstant
                    BannerH3  
                    BannerH4
                    BannerPara
                    BannerBtn
                    BannerImage
                    RightArrow
                    RightArrowYellow
                }
            }
        }
    `);
        return (
        <BannerWrapper id="homeContainer">
            <Container>
                <BannerContents>

                    <BannerContentRight>
                        <Fade bottom delay={1*JSONData.app2Json.Banner.DelayConstant}>
                            <BannerImage src={JSONData.app2Json.Banner.BannerImage} alt=""/>
                        </Fade>
                    </BannerContentRight>
                    <BannerContentLeft>
                        <Fade bottom delay={1*JSONData.app2Json.Banner.DelayConstant}>
                            <h1>{JSONData.app2Json.Banner.BannerH3}</h1>
                            <h2>{JSONData.app2Json.Banner.BannerH4}</h2>
                            <p>{JSONData.app2Json.Banner.BannerPara}</p>
                            <BannerBtn href="#">{JSONData.app2Json.Banner.BannerBtn}
                            </BannerBtn>
                        </Fade>
                    </BannerContentLeft>
                </BannerContents>
            </Container>
        </BannerWrapper>
    );
}

export default Banner;
