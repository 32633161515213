import React from 'react';
import {ServicesWrapper,ServicesHeading,ServicesInner,ServicesRightLi,ButtonWrapper,
    ServicesPara,ServicesHeadingWrapper,ServicesRightBtn,ServicesRightUl,ServicesRight,
    ServicesLeft,ServicesLiImgCol,CheckIcon,ServicesLiContentCol} from './services.style';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Fade from 'react-reveal/Fade';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const Services = () => {
    const JSONData  = useStaticQuery(graphql`{
  app2Json {
    Services {
      DelayConstant
      ServicesHeading
      ServicesPara
      ServicesLeftImg {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
      ServicesRightUl {
        ServicesRightLih3
        ServicesRightLipara
      }
      ServicesRightBtn
      RightArrow
      RightArrowYellow
    }
  }
}
`);
        return (
            <ServicesWrapper id="servicesContainer">
                <Container>
                    <Row>
                        <Col md="12">
                            <ServicesHeadingWrapper>
                                <Fade bottom delay={1*JSONData.app2Json.Services.DelayConstant}>
                                <ServicesHeading>{JSONData.app2Json.Services.ServicesHeading}</ServicesHeading>
                                </Fade>
                                <Fade bottom delay={2*JSONData.app2Json.Services.DelayConstant}>
                                <ServicesPara>{JSONData.app2Json.Services.ServicesPara}</ServicesPara>
                                </Fade>
                            </ServicesHeadingWrapper>
                        </Col>
                    </Row>
                    <ServicesInner>
                        <Row>
                            <Col lg="6" md="12">
                            <Fade left delay={1*JSONData.app2Json.Services.DelayConstant}>
                                <ServicesLeft>
                                    <GatsbyImage
                                        image={JSONData.app2Json.Services.ServicesLeftImg.childImageSharp.gatsbyImageData}
                                        className="ServicesLeftImg"
                                        imgStyle={{
                                            marginBottom:"0px"
                                        }}
                                        alt="" />
                                </ServicesLeft>
                            </Fade>
                            </Col>
                            <Col lg="6" md="12">
                                <ServicesRight>
                                    <Fade right delay={(1)*JSONData.app2Json.Services.DelayConstant}>
                                        <ServicesRightUl>
                                        {
                                            JSONData.app2Json.Services.ServicesRightUl.map((item,idx) => {
                                            return <ServicesRightLi>
                                                <ServicesLiImgCol>
                                                    <CheckIcon/>
                                                </ServicesLiImgCol>
                                                <ServicesLiContentCol>
                                                    <h6>{item.ServicesRightLih3}</h6>
                                                    <p>{item.ServicesRightLipara}</p>
                                                </ServicesLiContentCol>
                                            </ServicesRightLi>

                                            })
                                        }
                                        </ServicesRightUl>
                                    </Fade>
                                    <ButtonWrapper>
                                        <ServicesRightBtn href="#">{JSONData.app2Json.Services.ServicesRightBtn}
                                        </ServicesRightBtn>
                                    </ButtonWrapper>
                                </ServicesRight>
                            </Col>
                        </Row>
                    </ServicesInner>
                </Container>
            </ServicesWrapper>
        );
}

export default Services;
