import styled from 'styled-components';
import {Commonbtn} from '../Common/common.style';
import {device} from '../Common/device';
import BackgroundImg from '../../../assets/app2-images/effect.png'

export const BannerWrapper = styled.section`
    padding:70px 0px;
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size: auto 100%;

    @media ${device.tablet} {
        padding:70px 10px;
    }
`;

export const BannerContents = styled.div`
    padding-top:20px;
    display:flex;
    align-items:center;

    @media ${device.tablet} {
        display:block;
    }
`;

export const BannerContentLeft = styled.div`
    width:50%;
    padding-right:50px;

    @media ${device.tablet} {
        width:100%;
        text-align:center;
        padding-right:0px;
        margin-top:40px;
    }

    h1{
        margin-bottom:15px;
        font-weight:500;

        @media ${device.laptop} {
            font-size:45px;
            line-height:40px;
        }

        @media ${device.mobileL} {
            font-size:40px;
            line-height:36px;
        }
    }

    h2{
        margin-bottom:25px;
        @media ${device.laptop} {
            font-size:30px;
            line-height:30px;
        }

        @media ${device.tablet} {
            text-align:center;
        }

        @media ${device.mobileL} {
            font-size:28px;
            line-height:28px;
        }
    }

    p{
        color:#595959;
        margin-bottom:30px;
    }
`;



export const BannerContentRight = styled.div`
    width:50%;
    text-align:left;

    @media ${device.tablet} {
        width:100%;
        text-align:center;
        padding-top:15px;
    }
`;

export const BannerImage = styled.img`
    margin-bottom:0px;
    position: relative;
    z-index: 999;
    max-height: 500px;
    padding: 0px 20px;

    @media ${device.laptop} {
        width:85%;
    }

    @media ${device.tablet} {
        width:auto;
    }

`;
export const BannerBtn = styled(Commonbtn)`
    text-decoration:none;
`;
